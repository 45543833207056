
@font-face {
  font-family: 'KrabulerFont';
  src: url('../public/Fonts/RF\ Krabuler\ Regular.ttf') format('truetype');
  
}

body {
  margin: 0;
  font-family: 'KrabulerFont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'KrabulerFont';
}

:root {
  --verte-fonce: rgb(30,85, 54);
  --verte-claire: rgb(171, 212, 167);
  --marron-fonce: rgb(127, 91, 80);
  --beige-claire: rgb(244, 237, 227);
  
}