.leConcept-container {
    display: flex;
    flex-direction: column;
    background-color: var(--beige-claire);
}

.container-sections {
    padding-left: 6%;
    padding-right: 6%;
}
.vinculo-commander-concept{
    text-decoration: none;
    color: var(--beige-claire);
}

.titres {
    font-size: 3vw; 
    color: var(--verte-fonce);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

.paragraphes {
    font-size: 1.5vw;
}

/*--- Description Section---*/
.description-concept {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/public/Images/Cultive.png');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden; 
    background-attachment: fixed;
    z-index: 2;
    width: auto;
    height: 100vh;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
}
.negrita-exp-concept{
    color: var(--verte-claire);
}

.description-concept::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 39, 34, 0.353); 
    z-index: -1;
   
}

.description-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    overflow: hidden;
}
.titre-concept{
    text-shadow: 1px 1px 2px var(--verte-claire);
    font-size: 4vw;
    color: var(--beige-claire);
    margin: 0;
    padding: 0;
}
.sous-titre-descr {
    font-size: 2vw;
    color: var(--marron-fonce);
    background-color: var(--verte-claire); 
    border-radius: 5em; 
    padding: 0.5rem;
    margin: 0   ;
    text-align: center;
}

.paragraphe-descr {
    width: 90%;
    text-align: center;
    color: var(--beige-claire);
    font-size: 2vw;
}

/*--- Vision Section---*/
.vision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 10%;
    padding-top: 10%;
    z-index: 99;
}

.vision h2 {
    margin-bottom: 20px;
}

.wrapper-vision {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    text-align: justify;
    justify-items: center;
}

.contains {
    width: 80%;
    height: 100%;
    text-align: center;
    background-color: rgba(171, 212, 167, 0.678);
    padding: 2%;
    padding-top: 0;
    border-radius: 2rem;
    box-shadow: 12px 12px 2px 1px var(--marron-fonce);
}

.sous-titres {
    font-size: 3vw;
    color: var(--marron-fonce);
    background-color: var(--beige-claire);
}

.paragraphe-cuisine, .paragraphe-vision {
    color: var(--marron-fonce);
    font-size: 2vw;
}

.container-sections h2 {
    padding-bottom: 20px;
}
 /*--- Brunch Section (avant sale)---*/
 .titre-sale, .titre-sucre, .titre-pause{
    color: var(--verte-claire);
    padding-top: 10%;
    
 }

 .le-sale, .le-sucre, .la-pause{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100hv;
    background-color: var(--verte-claire);
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: center;
    align-content: center;
}

.descr-sale {
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding: 5%;
    margin: 0;
    align-items: center;
    justify-content: center;
    
}

.paragraphe-sale {
    text-align:justify;
    color: var(--beige-claire);
   
}

.descr-sale, .descr-sucre, .descr-pause{
    background-color: var(--marron-fonce);
} 
/*--- Images 3 sections, ---*/
.img-container {
    width: auto; 
    height: auto; 
    padding: 0;
    margin: 0;
}

.img-container img {
    width: 100%;  
    height: 100%; 
    object-fit: cover;  
}

/*--- La pause-dej Section---*/
.descr-pause {
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding: 5%;
    margin: 0;
    align-items: center;
    justify-content: center;
}
.descr-pause{
    margin: 0;
    justify-content: first baseline;
}
.subrayado{
    text-decoration: underline;
}
.paragraphe-pause {
    text-align:justify;
    color: var(--beige-claire);
    padding-right: 5%;
    
}
/*--- Les dejeneurs Section(avant sale)---*/
.color-verde{
    color: var(--verte-claire);
}

.descr-sucre {
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding: 5%;
    margin: 0;
    align-items: center;
    justify-content: center;
}

.paragraphe-sucre {
    text-align:justify;
    color: var(--beige-claire);
}
/*----- Button Commander----*/
.button-concept {
    --b: 3px;   
    --s: .15em; 
    --c: var(--beige-claire);
    
    padding: calc(.05em + var(--s)) calc(.3em + var(--s));
    color: var(--c);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--c) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .2em;
    margin: 2%;
  }
  .button-concept:hover,
  .button-concept:focus-visible{
    --_p: 0px;
    outline-color: var(--c);
    outline-offset: .05em;
    background-color: var(--verte-fonce);
  }
  .button-concept:active {
    background: var(--c);
    color: #fff;
  }
  
  .button-concept {
    font-family: 'KrabulerFont';
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    border: none;
    margin-top: 0;
    width: 180px;
    height: 70px;
    justify-self: center;
  }
  .negrita-text-sucre{
    color: var(--verte-fonce);
  }

/*------- Les Produits section --------*/

.les-produits{
    display: flex;
    justify-content: right;
    align-items: center;
    background-image: url('/public/Images/AssortiLegumes.JPG');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden; 
    background-attachment: fixed;
    z-index: 2;
    width: auto;
    height: 80vh;
}

.les-produits::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.div-produits{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    color: var(--marron-fonce);
    background-color: rgba(255, 245, 224, 0.906);
    padding: 10px;
    margin-right: 10%;
    border-radius: 1rem;
}
.paragraphe-produits{
    width: 100%;
    text-align: center;
    font-size: 2vw;
    
}

@media screen and (max-width: 960px){
    .titre-concept{
        font-size: 8vw;
        margin-bottom: 0;  
    }
    .titres {
        font-size: 7vw; 
    }
   
    .sous-titre-descr{
        font-size: 6vw;
    }
    .paragraphe-descr{
        font-size: 5vw;
        width: 100%;
    }
    .paragraphes {
        font-size: 4vw;
    }
    .sous-titres {
        font-size: 5vw;
    }
    .paragraphe-cuisine, .paragraphe-vision {
       
        font-size: 4vw;
    }
    /*-------Section Dej, Brunch, Pause----*/
    .le-sucre, .la-pause, .le-sale{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    
    .descr-sale{
        padding-bottom: 5%;
    }
    .descr-pause, .descr-sucre, .descr-sale{
        padding: 0 10% 10% 10%;

    }
    .img-sale{
        height: fit-content;
    }
    .descr-pause{
        order: 1;
    }
    .img-pause{
        order: 2;
    }
    .descr-pause{
        padding-bottom: 5%;
    }
    .vision {
        height: auto;
        padding: 10% 0;
    }
}

@media screen and (max-width: 750px){
    .titre-concept{
        font-size: 12vw; 
    }
    .sous-titre-descr{
        font-size: 7vw;
    }
    .paragraphe-descr{
        font-size: 7vw;
    }
    .sous-titres {
        font-size: 8vw;
    }
    .description-concept {
        clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        height: auto;
        padding-bottom: 20%;
        padding-top: 40%;
    }
    .titres {
        font-size: 10vw; 
        padding-bottom: 10%;
    }
    .paragraphes {
        font-size: 7vw;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 10%;
    }
    .para-vision{
        font-size: 7vw;
    }
    .vision {
        height: auto;
        padding: 20% 0;
    }
    .wrapper-vision {
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;
        
    }
    .le-sucre{
        height: auto;
    }
    .div-produits{
        width: 80%;
    }
   

}