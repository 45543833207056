/* SocialMediaModule.css */
.social-media-module {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    z-index: 1000; 
    width: 50px; 
}

.social-icon {
    font-size: 25px;
    color: var(--beige-claire); 
    text-decoration: none;
    transition: color 0.3s ease;
    width: 100%; 
    padding: 10px; 
    box-sizing: border-box; 
    text-align: center; 
    background-color: var(--verte-fonce);
    margin-top: 2px;
    border-radius: 5px 0 0 5px;
}

.social-icon:hover {
    background-color: var(--verte-claire);
}

@media (max-width: 750px) {
    .social-media-module {
        bottom: 0;  /* Fija el elemento en la parte inferior */
        top: auto;  /* Elimina cualquier fijación en la parte superior */
        right: 50%;  /* Centra horizontalmente */
        transform: translateX(50%);  /* Ajusta para centrar correctamente */
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr)); /* Reduce el tamaño mínimo de las columnas */
        width: 60%;  /* Reduce el ancho del banner */
        height: auto;  /* Ajusta la altura según el contenido */
        gap: 3px; /* Reduce el espacio entre iconos */
        padding: 5px;  /* Añade un padding para mejor visualización */
        margin-bottom: -10px;
    }
    
    .social-icon {
        font-size: 25px;  /* Reduce el tamaño de la fuente */
        padding: 8px;  /* Reduce el padding de los iconos */
        border-radius: 5px 5px 0 0;
        margin-top: 0;
        margin-right: 2px;
        
    }
}
