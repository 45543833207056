.footer-container{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    background-color: var(--verte-claire);
    
}
.footer-infos-essentiels p, .footer-politiques a, .footer-reservation a{
    color: var(--marron-fonce);
    font-size: 1.3vw;
}
a{
    text-decoration: none;
}
.infos-footer-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    justify-items: center;
    padding: 10px 0;
    text-align: center;
    padding-top: 3%;
    height: 40vh;
}
.infos-footer-container h3{
    color: var(--marron-fonce);
    font-size: 2vw;
}
.cont-reseaux{
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.icons-footer-reseaux{
    color: var(--marron-fonce);
}
.icons-footer-reseaux:hover, .footer-politiques a:hover, .footer-reservation a:hover{
    color: var(--beige-claire);
    cursor: pointer;
}
.footer-reseaux a{
    font-size: 4vw;
}

.footer-politiques{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 30px;
}

.copyright{
    background-color: black;
    width: 100%;
    padding: 0;
    margin-top: 30px;
    justify-content: center;
    text-align: center;
    color: var(--beige-claire);
}

@media screen and (max-width: 960px){
    .infos-footer-container{
        height: auto;
    }
    .infos-footer-container h3{
        font-size: 5vw;
    }
    .footer-infos-essentiels p, .footer-reservation a,  .footer-politiques a{
        font-size: 3vw;
    }
    .footer-reseaux a{
        font-size: 8vw;
        gap: 0;
        padding: 0;
        margin: 0;
    }
    .icons-footer-reseaux{
        padding: 5px;
    }
    .cont-reseaux{
        gap: 10px;
    }
  
}

@media screen and (max-width: 750px){

    .infos-footer-container h3{
        font-size: 8vw;
    }
    .footer-infos-essentiels p, .footer-reservation a,  .footer-politiques a{
        font-size: 6vw;
    }
    .footer-politiques{
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        padding-top: 20%;
    }
    .infos-footer-container{
        grid-template-columns: 1fr;
        gap: 10px;
        height: auto;
        
    }
    .footer-reseaux a{
        font-size: 20vw;
    }
    .icons-footer-reseaux{
        padding: 15px;
       
    }
    .copyright{
        padding-bottom: 50px;
    }

}