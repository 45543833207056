@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poetsen+One&display=swap');
@import url('https://db.onlinewebfonts.com/c/d45c595a0219ea91eb5851cf0f8f96d1?family=RFKrabuler-Regular');
/*---------Modal ----------*/
/*-------------------------*

/* Estilos generales del modal */
.modal {
    display: block; /* Mostrar el modal cuando esté activo */
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
}

/* Contenido del modal */
.modal-content {
    background-color: var(--beige-claire);
    margin: 10% auto; /* Margen superior reducido para pantallas pequeñas */
    padding: 2%;
    border: 1px solid #888;
    width: 90%; /* Ajuste flexible del ancho */
    max-width: 500px; /* Limita el ancho máximo en pantallas grandes */
    border-radius: 10px;
    position: relative;
    top: 10%;
}

/* Botón de cierre del modal */
.close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #aaa;
    font-size: 2vw;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: rgb(251, 5, 5);
    text-decoration: none;
    cursor: pointer;
}

/* Botones dentro del modal */
.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10%;
    flex-wrap: wrap; /* Permite que los botones se ajusten en pantallas pequeñas */
}

/* Imagen dentro del modal */
.modal-content img {
    width: 80%; /* Ajuste flexible de la imagen */
    max-width: 200px; /* Limita el tamaño máximo de la imagen */
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Estilos de los botones */
.modal-buttons button {
    padding: 10px 20px;
    font-size: 1.5vw; /* Ajuste de tamaño de fuente para pantallas pequeñas */
    font-family: "RFKrabuler-Regular";
    cursor: pointer;
    background-color: var(--verte-fonce);
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    transition: background-color 0.3s ease;
}

.modal-buttons button:hover {
    background-color: var(--verte-claire);
}

/* Título del modal */
.modal-content h1 {
    color: var(--marron-fonce);
    text-align: center;
    font-size: 2vw; /* Tamaño de fuente ajustado */
    margin: 20px 0;
}



@media screen and (max-width: 960px){
   
    
    .modal-content {
        width: 95%; /* Mayor flexibilidad en pantallas pequeñas */
        top: 25%;
    }

    .modal-buttons button {
        font-size: 1rem; /* Ajusta el tamaño de los botones para pantallas pequeñas */
        padding: 8px 16px;
    }

    .modal-content h1 {
        font-size: 1.5rem; /* Ajusta el tamaño del título */
    }
}

@media screen and (max-width: 750px){
   
  
    .modal-content {
        padding: 5%; /* Ajusta el padding para pantallas muy pequeñas */
        top: 25%;
    }

    .modal-buttons button {
        font-size: 0.9rem; /* Tamaño de fuente más pequeño */
        padding: 6px 12px;
    }

    .modal-content img {
        width: 70%; /* Ajuste de la imagen para pantallas muy pequeñas */
    }

    .modal-content h1 {
        font-size: 1.2rem; /* Tamaño del título más pequeño */
    }
   
}