
:root {
    --primary: rgb(30,85, 54);
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'KrabulerFont';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn--primary{
    background-color: var(--primary);
    color: rgb(244, 237, 227);
    border: 1px solid var(--primary);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 5px;

}

.btn--outline {
    background-color: transparent;
    color: rgb(244, 237, 227);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: rgb(171, 212, 167);
    color: rgb(244, 237, 227);
    transition: 250ms;
}