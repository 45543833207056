@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poetsen+One&display=swap');
@import url('https://db.onlinewebfonts.com/c/d45c595a0219ea91eb5851cf0f8f96d1?family=RFKrabuler-Regular');
.banner-container {
    position: relative;
    width: auto;
    height: 100vh; /* Ajusta la altura según sea necesario */
    overflow: hidden;
}
.vinculo-commander-banner{
    text-decoration: none;
    color: var(--beige-claire);
    
}
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta el color y la opacidad del overlay según lo necesites */
    z-index: -1;
}

.cont-banner-descript{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 3%;
}
.sous-titre-banner{
    color: var(--verte-claire);
    font-size: 2vw;
    padding: 0;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.logo_banner_home{
    width: 50%;
    padding: 0;
    margin: 0;
    
}
.texte-antespara{
    color: var(--verte-claire);
    font-size: 2.5vw;
    padding: 0;
    margin: 0;
} 
.para-banner{
    color: var(--beige-claire);
    font-size: 2vw;
    margin: 0;
    padding: 0;
    text-align: center;
}

.banner-btns {  
    margin-top: 5%;
   
}

.moto-icon {
    padding-left: 6px;
    font-size: 2rem;
}



@media screen and (max-width: 960px){
    .banner-container {
        padding-top: 10%;
    }
    .sous-titre-banner, .texte-antespara{
        font-size: 5vw;
    } 
    .para-banner{
        font-size: 4vw;
        width: 80%;
    }
    .logo_banner_home{
        width: 70%;
    }
    .banner-btns {  
        padding-top: 20%;
    }
    
    
}

@media screen and (max-width: 750px){
   
    .logo_banner_home{
        width: 90%;
        
    }
    .sous-titre-banner, .texte-antespara{
        font-size: 8vw;
    } 
    .para-banner{
        font-size: 7vw;
        width: 90%;
        
    }
    
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    
}