.container {
  padding: 75px 0;
  margin: 0 auto;
  width: 80%;
  justify-content: center;
  display: flex;
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 50vh;
}

.item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: none; /* Elimina la transición para evitar cambios de tamaño */
}

.item-1 {
  background-image: url('/public/Images/Event1.jpg');
}

.item-2 {
  background-image: url('/public/Images/Event2.jpg');
}

.item-3 {
  background-image: url('/public/Images/Event3.jpg');
}

.item-4 {
  background-image: url('/public/Images/Event4.jpg');
}

.item-5 {
  background-image: url('/public/Images/Event5.jpg');
}

/* Media query para pantallas con un ancho máximo de 960px */
@media (max-width: 960px) {
  .container {
      width: 80%;
      padding: 50px 0;
  }

  .item {
      height: 40vh;
      flex: 1 1 auto;
  }
}

/* Media query para pantallas con un ancho máximo de 390px */
@media (max-width: 700px) {
  .container {
      width: 80%;
      padding: 30px 0;
  }

  .gallery-wrap {
      flex-direction: column;
      height: auto;
  }

  .item {
      height: 30vh;
      flex: 1 1 auto;
  }
}
